import MessageType, {
  IMAGE_TYPES,
  VIDEO_TYPES,
  VIDEO_CHAT_TYPES,
  MEDIA_TYPES,
} from '../constants/messageType';

// Checks if the message type is a photo message
export const isPhotoMessage = (type: MessageType) => IMAGE_TYPES.includes(type);

// Checks if the message type is a sticker message
export const isStickerMessage = (type: MessageType) =>
  MessageType.STICKER === type;

// Checks if the message type is a gift message
export const isGiftMessage = (type: MessageType) => MessageType.GIFT === type;

// Checks if the message type is a video message
export const isVideoMessage = (type: MessageType) => VIDEO_TYPES.includes(type);

// Checks if the message type is a video chat message
export const isVideoChatMessage = (type: MessageType) =>
  VIDEO_CHAT_TYPES.includes(type);

// Checks if the message type is a private photo request message
export const isPrivatePhotoRequestMessage = (type: MessageType) =>
  MessageType.PRIVATE_PHOTO_REQUEST === type;

// Checks if the message type is a virtual assistant welcome message
export const isVirtualAssistantWelcomeMessage = (type: MessageType) =>
  MessageType.VIRTUAL_ASSISTANT_WELCOME === type;

// Checks if the message type is a PMA photo response message
export const isPmaPhotoResponseMessage = (type: MessageType) =>
  MessageType.PMA_PHOTO_RESPONSE === type;

// Checks if the message type is a media message
export const isMediaMessage = (type: MessageType) => MEDIA_TYPES.includes(type);
