import CommunicationRestriction from '../constants/CommunicationRestriction';
import updateRecipientCache from '../../common/utils/updateRecipientCache';
import type {RecipientWithMessagesFragment} from '../../common/graphql/fragments/recipientMessages';

/**
 * Removes a message from the cache.
 * @param recipientId - The ID of the recipient.
 * @param messageId - The ID of the message to remove.
 * @param error - The error to show if the recipient has blocked the current user.
 *
 * @see sendMessage
 */
const removeMessageFromCache = (
  recipientId: string,
  messageId: string,
  error: string | null = null,
) =>
  updateRecipientCache(
    recipientId,
    (cacheRecipient: RecipientWithMessagesFragment) => {
      const {messages, user, lastMessage} = cacheRecipient;

      if (!messages.length) return cacheRecipient;

      const nextMessages = messages.filter(({id}) => id !== messageId);
      const recipient: Partial<RecipientWithMessagesFragment> = {
        messages: nextMessages,
      };

      // recipient has blocked current user, communication restricted and we need to show cap
      if (error === CommunicationRestriction.BLOCKED_USER && user) {
        recipient.user = {
          ...user,
          blockUser: {...user.blockUser, heMe: true},
        };
      }

      if (messageId === lastMessage.id) {
        const message = nextMessages[nextMessages.length - 1];

        recipient.lastMessage = {
          ...lastMessage,
          id: message?.id || recipientId,
          type: message?.type || '',
          text: message?.text || '',
          isSending: false,
        };
      }

      return {...cacheRecipient, ...recipient};
    },
  );

export default removeMessageFromCache;
