import {sessionStorage} from '@core/utils/storage';

import {SKIPPED_FIRST_GO_TO_PP_KEY} from '../constants/storageKeys';

// Flag says that the first go to PP was skipped
const checkSkippedFirstGoToPP = (needUpdate: boolean) => {
  const skippedFirstGoToPP = Boolean(
    sessionStorage.getItem(SKIPPED_FIRST_GO_TO_PP_KEY),
  );

  if (needUpdate && !skippedFirstGoToPP) {
    sessionStorage.setItem(SKIPPED_FIRST_GO_TO_PP_KEY, 1);
  }

  return skippedFirstGoToPP;
};

export default checkSkippedFirstGoToPP;
