import {useMemo, useEffect, useRef} from 'react';
import map from 'lodash/map';
import pick from 'lodash/pick';
import find from 'lodash/find';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import includes from 'lodash/includes';
import {useReactiveVar} from '@apollo/client';

import {Method} from '@core/types/graphql';
import activeMethodTabVar from '@core/graphql/vars/activeMethodTabVar';
import showFailFormVar from '@core/graphql/vars/showFailFormVar';

import {closeGooglePayCvvForm} from '../../../forms/googlePay/utils/useIsGooglePayCvvForm';
import {METHODS_WITHOUT_TAB} from '../../../common/constants/paymentMethods';
import usePaymentParams from '../../../common/utils/usePaymentParams';
import type {TabProps} from '../types/tabProps';
import useActivePackage from '../../package/utils/useActivePackage';

type Package = {
  method: string;
  tabName: string;
  tabLogo: string;
};

type PaymentTabsPropsOptions<T> = {
  packages: T[];
  defaultActiveTab: string;
};

export type SetActiveTab = (tab: Pick<TabProps, 'tabName'>) => void;

type PaymentTabs<T> = {
  activeTab: TabProps;
  packagesByTab: T[];
  setActiveTab: SetActiveTab;
  tabs: TabProps[];
};

/**
 * Defines the order of payment method tabs.
 * TODO: remove the sorting logic if tabs order is properly defined by the server.
 */
const TABS_ORDER = {
  // Place 'midas' / 'card' at the beginning of the list to be the first tab.
  // It's important for them to be adjacent to avoid tab placement change if midas fails to load and falls back to 'card'.
  [Method.card]: 0,
  [Method.midas]: 0,
  // Other tabs will appear later in the list.
};

/**
 * Takes packages list and return formatted data about payment tabs
 * Use only for display payment methods tabs. To take current payment method use active package
 * Use hook only once in base payment layout, because hook used state (useState)
 */
export default function usePaymentTabs<T extends Package>({
  packages,
  defaultActiveTab,
}: PaymentTabsPropsOptions<T>): PaymentTabs<T> {
  const {viaMethod} = usePaymentParams();
  const activePackageByTab = useRef({});
  const activeTabName = useReactiveVar(activeMethodTabVar);
  const {activePackage, setActivePackage} = useActivePackage();

  const {tabs, packagesTabMap} = useMemo(() => {
    // map (tab) -> [packages]
    const packagesMap = groupBy(
      filter(packages, (item) => !includes(METHODS_WITHOUT_TAB, item.method)),
      'tabName',
    );

    // Array of objects contains tabName and tabLogo
    const tabsList: TabProps[] = map(packagesMap, (packagesList) =>
      pick(packagesList[0], ['tabName', 'tabLogo']),
    );

    return {
      packagesTabMap: packagesMap,
      tabs: sortBy(
        tabsList,
        ({tabName}) => TABS_ORDER[tabName] ?? 99, // just a large number to display rest of tabs later in the list.
      ),
    };
  }, [packages]);

  // Default tab
  const defaultTab = useMemo<TabProps>(() => {
    return (
      (viaMethod && find(tabs, ['tabName', viaMethod])) ||
      find(tabs, ['tabName', defaultActiveTab])
    );
  }, [tabs, defaultActiveTab, viaMethod]);

  /**
   * Must return active tab for default name in all cases because backend can return fewer packages after decline, and
   * tab for active tab name will be empty
   */
  const activeTab = useMemo<TabProps>(() => {
    return (
      find(tabs, ['tabName', activeTabName]) ||
      find(tabs, ['tabName', defaultTab?.tabName])
    );
  }, [tabs, activeTabName, defaultTab]);

  // Reset packages mapping to tab when packages (PP) changed
  useEffect(() => {
    if (!isEmpty(packages)) {
      activePackageByTab.current = {};
    }
  }, [packages]);

  // Set default tab
  useEffect(() => {
    if (activeTab && activeTabName !== activeTab.tabName) {
      activeMethodTabVar(activeTab.tabName);
    }
  }, [activeTabName, activeTab]);

  useEffect(() => {
    if (packagesTabMap?.[activeTabName]?.includes(activePackage)) {
      activePackageByTab.current[activePackage.tabName] = activePackage;
    }
  }, [activeTabName, activePackage, packagesTabMap]);

  return {
    tabs,
    activeTab,
    setActiveTab({tabName}) {
      if (!(tabName in activePackageByTab.current)) {
        const tabPackages = packagesTabMap[tabName];

        activePackageByTab.current[tabName] =
          find(tabPackages, 'isDefault') || tabPackages[0];
      }

      setActivePackage(activePackageByTab.current[tabName]);
      // Prevent to hide the fail form if the same tab clicked
      if (activeTab?.tabName !== tabName) {
        showFailFormVar(false);
        closeGooglePayCvvForm();
      }
      activeMethodTabVar(tabName);
    },
    packagesByTab: packagesTabMap[activeTab?.tabName] || [],
  };
}
