import BENEFITS from './benefits';

const BENEFIT_ICONS = {
  [BENEFITS.DETAILS]: 'profile',
  [BENEFITS.EMAIL]: 'mail',
  [BENEFITS.LIKED]: 'heart',
  [BENEFITS.PLATFORMS]: 'computer',
  [BENEFITS.SEARCH]: 'search',
  [BENEFITS.ALL_PHOTOS]: 'gallery',
  [BENEFITS.SHARE_PHOTO]: 'photo',
  [BENEFITS.UNLIMITED_LIKE_GALLERY]: 'heart-match',
  [BENEFITS.SAFETY]: 'lock',
  [BENEFITS.PREMIUM_SUPPORT]: 'call',
  [BENEFITS.INCOGNITO]: 'incognito',
  [BENEFITS.CLEANING]: 'history-clean',
  [BENEFITS.XXX_VIDEOS]: 'video',
  [BENEFITS.DELETE_CHAT]: 'trash',
  [BENEFITS.EXTRA_BOOST]: 'crown',
  [BENEFITS.HOT_PHOTOS]: 'gallery',
  [BENEFITS.PHOTO_AND_VIDEOS]: 'photo',
  [BENEFITS.SAFE_SYSTEM]: 'trusted',
  [BENEFITS.UNLIMITED_CHATS]: 'chat',
  [BENEFITS.USER_VIDEOS]: 'video',
  [BENEFITS.TOP_MESSENGER]: 'favorite',
  [BENEFITS.TOP_SEARCH]: 'arrow-top',
  [BENEFITS.GET_RECEIPT]: 'chat-check',
  [BENEFITS.X5_PEOPLE]: 'people',
  [BENEFITS.ENHANCED_ACCESS_CONTROL]: 'settings',
  [BENEFITS.FASTER_CONNECTIONS]: 'lightning',
  [BENEFITS.MATCHES_IN_CHATS]: 'chat',
  [BENEFITS.UNLIMITED_MESSAGES]: 'chat',
  [BENEFITS.ENDLESS_MATCHES]: 'heart-match',
  [BENEFITS.REVEAL_YOU_VISITORS]: 'incognito',
  [BENEFITS.REVEAL_INCOMMING_LIKES]: 'lips',
  [BENEFITS.SEND_PRIVATE_PICKS]: 'heart-match',
  [BENEFITS.CUSTOM_SEARCH_FILTERS]: 'goal',
  [BENEFITS.WATCH_TOP_STORIES]: 'gallery',
  [BENEFITS.UNLIMITED_SEARCH]: 'search',
  [BENEFITS.MARK_AS_READ]: 'check-double',
  [BENEFITS.UNLIMITED_LIKES]: 'heart',
  [BENEFITS.COINS_BALANCE_BOOST_500]: 'gift',
  [BENEFITS.EXTRA_COINS_PAYMENT]: 'coin',
  [BENEFITS.PACKAGE_EXTRA_COINS]: 'fire',
  [BENEFITS.COINS_MONTHLY_200]: 'login',
};

export default BENEFIT_ICONS;
