import memoize from 'lodash/memoize';

import createTranslationsMap from '@core/translations/createTranslationsMap';
import getLocale from '@core/translations/utils/getLocale';
import logger from '@core/logger';
import isEnabledReactPaymentPage from '@core/payment/common/utils/isEnabledReactPaymentPage';

import BENEFITS from '../constants/benefits';

export const BENEFITS_TRANSLATES = createTranslationsMap((t) => ({
  /**
   * Hybrid benefits start
   * @TODO: this translations to backend
   */
  [BENEFITS.SPECIAL_OFFER_5]: t(
    'paymentPagePackage',
    'title.special_offer_with_discount',
    {
      '{discount}': 20,
      '{amount}': 5,
    },
  ),
  [BENEFITS.SPECIAL_OFFER_32]: t(
    'paymentPagePackage',
    'title.special_offer_with_discount',
    {
      '{discount}': 40,
      '{amount}': 32,
    },
  ),
  [BENEFITS.SPECIAL_OFFER_20]: t(
    'paymentPagePackage',
    'title.special_offer_free_messages',
    {
      '{discount}': 20,
      '{amount}': 10,
    },
  ),
  [BENEFITS.SPECIAL_OFFER_40]: t(
    'paymentPagePackage',
    'title.special_offer_free_messages',
    {
      '{discount}': 40,
      '{amount}': 20,
    },
  ),
  /**
   * Hybrid benefits end
   */

  /**
   * Coins benefits start
   * @TODO: remove them after migrating COINS to react PP. Translates added on backend.
   */
  [BENEFITS.UNLIMITED_SEARCH]: t(
    'paymentPagePackage',
    'title.unlimited_search',
  ),
  [BENEFITS.PREMIUM_SUPPORT]: t('paymentPagePackage', 'title.premium-support'),
  [BENEFITS.MARK_AS_READ]: t('paymentPagePackage', 'title.mark_as_read'),
  [BENEFITS.COINS_BALANCE_BOOST_500]: t(
    'paymentPagePackage',
    'title.500_coins_balance_boost',
  ),
  [BENEFITS.EXTRA_COINS_PAYMENT]: t(
    'paymentPagePackage',
    'title.extra_coins_payment',
  ),
  [BENEFITS.PACKAGE_EXTRA_COINS]: t(
    'paymentPagePackage',
    'title.package_extra_coins_payment',
  ),
  [BENEFITS.COINS_MONTHLY_200]: t(
    'paymentPagePackage',
    'title.200_coins_monthly',
  ),
  /**
   * Coins beneifts end
   */
}));

const getBenefitTranslation = memoize(
  (benefit, serverTranslation) => {
    const frontTranslation = BENEFITS_TRANSLATES[benefit];
    const locale = getLocale();

    if (serverTranslation) {
      if (frontTranslation) {
        logger.sendWarning(
          `[getBenefitTranslation][${locale}] Frontend translation should be removed for benefit "${benefit}"`,
        );
      }

      return serverTranslation;
    }

    if (frontTranslation) {
      isEnabledReactPaymentPage() &&
        logger.sendWarning(
          `[getBenefitTranslation][${locale}] Translation for benefit "${benefit}" should be moved from frontend to backend translations`,
        );

      return frontTranslation;
    }

    logger.sendWarning(
      `[getBenefitTranslation][${locale}] No translation for benefit "${benefit}" on backend/frontend. Should be added backend translation`,
    );

    return benefit;
  },
  (...args) => {
    return args.join('::');
  },
);

export default getBenefitTranslation;
