import uniqueId from 'lodash/uniqueId';

import {getClientInstance} from '@core/graphql/client';
import CURRENT_USER_ID_QUERY from '@core/user/profile/current/graphql/queries/currentUserId.gql';
import {Direction} from '@core/types/graphql';

import MESSAGE_PHOTO_FRAGMENT from '../graphql/fragments/messagePhoto.gql';
import MESSAGE_VIDEO_FRAGMENT from '../graphql/fragments/messageVideo.gql';
import updateRecipientCache from './updateRecipientCache';
import {isPhotoMessage, isStickerMessage, isVideoMessage} from './messageType';
import MESSAGE_MARKER from '../constants/messageMarker';

/**
 * @param {string} id - photo id
 * @returns {?object} MessagePhoto
 */
const getPhotoById = (id) =>
  getClientInstance().readFragment({
    fragment: MESSAGE_PHOTO_FRAGMENT,
    id: `MessagePhoto:${id}`,
  });

/**
 * @param {string} id - video id
 * @returns {?object} MessageVideo
 */
const getVideoById = (id) =>
  getClientInstance().readFragment({
    fragment: MESSAGE_VIDEO_FRAGMENT,
    id: `MessageVideo:${id}`,
  });

/**
 * Creates temporary message
 * @param {string} messageType - message type
 * @param {string} message - message text
 * @param {number} contentLevel
 * @param {string} uniqueIdPrefix - prefix for create an unique message id
 * @returns {object} GraphQL-compatible Message data
 */
export const createMessage = (
  {messageType, message, contentLevel} = {},
  uniqueIdPrefix = '',
) => {
  const client = getClientInstance();
  const query = client.readQuery({query: CURRENT_USER_ID_QUERY});

  /**
   * @see formatMessagePhoto if you want to change the data here
   */
  const messagePhoto = isPhotoMessage(messageType)
    ? getPhotoById(message) || {
        id: message,
        level: 0,
        censored: false,
        updatedOn: '0000-00-00 00:00:00',
        isApprovedFromMessenger: null,
        error: null, // client field to show upload error
        __typename: 'MessagePhoto',
      }
    : null;

  /**
   * @see formatMessageVideo if you want to change the data here
   */
  const messageVideo = isVideoMessage(messageType)
    ? getVideoById(message) || {
        id: message,
        previewUrl: '',
        videoPreviewUrl: '',
        url: '',
        isConverted: false,
        level: 0,
        isApprovedFromMessenger: null,
        percentage: 0, // client field to show upload progress
        error: null, // client field to show upload error
        __typename: 'MessageVideo',
      }
    : null;

  const messageSticker = isStickerMessage(messageType)
    ? {
        url: message,
        contentLevel,
        __typename: 'MessageSticker',
      }
    : null;

  return {
    id: uniqueId(uniqueIdPrefix || 'sending:'),
    text: message,
    timestamp: Math.floor(Date.now() / 1000),
    senderId: query.myUser.profile.id,
    subject: 'hi',
    direction: Direction.outgoing,
    needBlur: false,
    type: messageType,
    photo: messagePhoto,
    video: messageVideo,
    sticker: messageSticker,
    chatLegend: MESSAGE_MARKER.DEFAULT,
    upgradeReadVia: '',
    isPaidForView: false,
    isRead: false,
    isUnsent: false,
    isSending: true, // client field
    __typename: 'Message',
  };
};

/**
 * Creates new sending message and inserts it to Recipient.messages in apollo cache
 * @param {string} recipientId
 * @param {object} messageData {@see createMessage} params
 * @param {string?} uniqueIdPrefix {@see createMessage} params
 * @return {string} generated sending message id
 */
const addSendingMessageToCache = (recipientId, messageData, uniqueIdPrefix) => {
  const message = createMessage(messageData, uniqueIdPrefix);
  let isUpdate = false;

  try {
    updateRecipientCache(recipientId, ({messages, unsentMessageCount}) => {
      /**
       * The recipient or messages may not be in the cache
       */
      if (Array.isArray(messages)) {
        /**
         * Set a message like isUnsent if recipient has unsent messages
         * Need for correct update message view after message will come
         * It is possible because after buy coins all of unsent messages will be sent immediately,
         * and an user can`t have at one moment unsent messages and sent new messages
         */
        const isUnsent = unsentMessageCount > 0 ? true : message.isUnsent;
        isUpdate = true;
        return {
          messages: [...messages, {...message, isUnsent}],
          lastMessage: {
            id: message.id,
            type: message.type,
            text: message.text,
            timestamp: message.timestamp,
            isSending: true,
            __typename: 'LastMessage',
          },
        };
      }
      return null;
    });
    // eslint-disable-next-line no-empty
  } catch (e) {}

  // If t message did not set up to recipient, wont return id
  return isUpdate ? message.id : null;
};

export default addSendingMessageToCache;
