import cloneDeep from 'lodash/cloneDeep';

import type {Client} from '@core/graphql/types';
import {getClientInstance} from '@core/graphql/client';

import type MessageType from '../constants/messageType';
import {VIDEO_CHAT_TYPES} from '../constants/messageType';
import COUNT_FREE_MESSAGES_QUERY from '../graphql/queries/countFreeMessages.gql';
import FREE_ASSISTANT_MESSAGES_QUERY from '../graphql/queries/freeAssistantMessages.gql';
import type {CountFreeMessagesQuery} from '../graphql/queries/countFreeMessages';
import type {FreeAssistantMessagesQuery} from '../graphql/queries/freeAssistantMessages';
import updateRecipientMessagePermissions from './updateRecipientMessagePermissions';

type FreeMessagesData = {
  count: number;
  show: boolean;
};

type Message = {
  type: MessageType;
  countFreeMessages?: {
    countFreeMessages: number;
    show: boolean;
  };
  freeAssistantMessages?: FreeMessagesData;
};

/**
 * Updates free messages data and replaces canSend in messagePermissions with canSendNext flag
 * Should be called after successful message sending
 */
const updateFreeMessagesInCache = ({
  client,
  count,
  show,
}: {
  client: Client;
  count: number;
  show: boolean;
}) =>
  client
    .query<CountFreeMessagesQuery>({
      query: COUNT_FREE_MESSAGES_QUERY,
    })
    .then(({data: cachedData}) => {
      if (!cachedData?.messenger?.initialData) return;

      const data = cloneDeep(cachedData);
      const {freeMessages} = data.messenger.initialData;

      freeMessages.count = count;
      freeMessages.show = show;

      client.writeQuery<CountFreeMessagesQuery>({
        query: COUNT_FREE_MESSAGES_QUERY,
        data,
      });
    });

const updateFreeAssistantMessagesInCache = ({
  client,
  count,
  show,
}: {
  client: Client;
  count: number;
  show: boolean;
}) => {
  const data = client.readQuery<FreeAssistantMessagesQuery>({
    query: FREE_ASSISTANT_MESSAGES_QUERY,
  });

  if (!data?.messenger?.initialData) {
    return;
  }

  client.writeQuery<FreeAssistantMessagesQuery>({
    query: FREE_ASSISTANT_MESSAGES_QUERY,
    data: {
      ...data,
      messenger: {
        ...data.messenger,
        initialData: {
          ...data.messenger.initialData,
          freeAssistantMessages: {
            ...data.messenger.initialData.freeAssistantMessages,
            count,
            show,
          },
        },
      },
    },
  });
};

type UpdateMessageDataInCacheParams = {
  userId: string;
  message: Message;
  client?: Client;
  canSendNext: boolean;
  needSendFirst: boolean;
  reason: string;
};

/**
 * Updates the permissions cache after sending or receiving message.
 * Should be called after successful message sending or after incoming message
 */
const updateMessageDataInCache = ({
  userId,
  message,
  client = getClientInstance(),
  canSendNext,
  needSendFirst,
  reason,
}: UpdateMessageDataInCacheParams) => {
  if (VIDEO_CHAT_TYPES.includes(message.type)) {
    // Skip update for video chat messages, because they don't affect the free messages permissions.
    return;
  }

  updateRecipientMessagePermissions({
    client,
    userId,
    canSendNext,
    reason,
    needSendFirst,
  });

  if (message.countFreeMessages) {
    const {countFreeMessages, show} = message.countFreeMessages;
    updateFreeMessagesInCache({client, count: countFreeMessages, show});
  }

  if (message.freeAssistantMessages) {
    updateFreeAssistantMessagesInCache({
      ...message.freeAssistantMessages,
      client,
    });
  }
};

export default updateMessageDataInCache;
