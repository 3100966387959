import {getClientInstance} from '@core/graphql/client';

import MESSENGER_QUERY from '../graphql/queries/messenger.gql';
import type {MessengerQuery} from '../graphql/queries/messenger';
import updateRecipientCache from './updateRecipientCache';

export default function removeRecipientsInCache(deletedUserIds: string[]) {
  const client = getClientInstance();
  const messengerData = client.readQuery<MessengerQuery>({
    query: MESSENGER_QUERY,
  });

  if (!messengerData) {
    return;
  }

  const recipients = messengerData.messenger.initialData.recipients.filter(
    (recipient) => !deletedUserIds.includes(recipient.id),
  );

  if (
    recipients.length === messengerData.messenger.initialData.recipients.length
  ) {
    return;
  }

  client.writeQuery<MessengerQuery>({
    query: MESSENGER_QUERY,
    data: {
      ...messengerData,
      messenger: {
        ...messengerData.messenger,
        initialData: {
          ...messengerData.messenger.initialData,
          recipients,
        },
      },
    },
  });

  // remove messages cache
  deletedUserIds.forEach((id) => {
    updateRecipientCache(id, {
      messages: [],
      unreadMessageCount: 0,
    });
  });
}
