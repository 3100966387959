import isBoolean from 'lodash/isBoolean';

import {getClientInstance} from '@core/graphql/client';

import type {MessengerQuery} from '../graphql/queries/messenger';
import type {RecipientWithMessagesFragment} from '../graphql/fragments/recipientMessages';
import MESSENGER_QUERY from '../graphql/queries/messenger.gql';
import updateRecipientMessagePermissions from './updateRecipientMessagePermissions';

type RecipientUpdater = (recipient: RecipientWithMessagesFragment) => boolean;

/**
 * Update canSend permission for recipients
 * @param updater - Function that determines the new canSend value for each recipient
 * @param onlyInCache - Whether to only update the cache without making a network request
 *
 * Sometimes needs to exclude the fetching data if user doesn't have a cache.
 * @see deleteMessages
 */
export default function updateRecipientsMessagePermissions(
  updater: RecipientUpdater,
  onlyInCache: boolean = false,
) {
  const client = getClientInstance();
  const data = client.readQuery<MessengerQuery>({query: MESSENGER_QUERY});

  if (!data?.messenger?.initialData?.recipients) {
    return;
  }

  data.messenger.initialData.recipients.forEach(
    (recipient: RecipientWithMessagesFragment) => {
      const canSend = updater(recipient);

      if (isBoolean(canSend)) {
        updateRecipientMessagePermissions({
          client,
          userId: recipient.id,
          canSendNext: canSend,
          onlyInCache,
        });
      }
    },
  );
}
