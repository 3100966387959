const MESSAGE_MARKER = {
  DEFAULT: 'default',
  PMA_REPLY: 'pmareply',
  FLIRTCAST: 'flirtcast',
  PROMO: 'promo',
  SEX_ALARM: 'sex_alarm',
  FLIRT_ALARM: 'flirt_alarm',
  PMA_REPLY_ICON: 'bullet',
  FLIRTCAST_ICON: 'bullet',
  SEX_ALARM_ICON: 'bullet',
  FLIRT_ALARM_ICON: 'bullet',
  PROMO_ICON: 'bullet',
  NOT_READ_ICON: 'check',
  READ_ICON: 'check-doubl',
};

export default MESSAGE_MARKER;
