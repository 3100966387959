import getHistory from '@core/application/utils/getHistory';
import {readFromLocalStorage} from '@core/utils/storage';

import {MINI_MESSENGER_VISIBLE_RECIPIENT_KEY} from '../constants/storageKeys';
import isMiniMessengerAllowed from './isMiniMessengerAllowed';

const CHAT_PAGE = '/chat/with/';

const getActiveRecipientId = async (): Promise<string | null> => {
  const {
    location: {pathname},
  } = getHistory();

  if (pathname.startsWith(CHAT_PAGE)) {
    return pathname.replace(CHAT_PAGE, '');
  }

  if (await isMiniMessengerAllowed()) {
    const storageData = readFromLocalStorage(
      MINI_MESSENGER_VISIBLE_RECIPIENT_KEY,
    );

    if (storageData) {
      return storageData;
    }
  }

  return null;
};

export default getActiveRecipientId;
