import sortBy from 'lodash/sortBy';

import {getClientInstance} from '@core/graphql/client';
import {readFromLocalStorage} from '@core/utils/storage';

import {MINI_MESSENGER_VISIBLE_RECIPIENT_KEY} from '../constants/storageKeys';
import type {MessengerQuery} from '../graphql/queries/messenger';
import MESSENGER_QUERY from '../graphql/queries/messenger.gql';

type OpenMessengerParams = {
  userId: string;
  replace: boolean;
  skipMiniMessenger: boolean;
  needGTMTrack: boolean;
};

type OpenMessengerWith = (params: OpenMessengerParams) => Promise<void>;

/**
 * @see src/packages/dating/activity/containers/ChatButton
 * @see src/packages/speedDating/activity/containers/ChatButton
 */
const openMessenger = async (
  openMessengerWith: OpenMessengerWith,
  needGTMTrack = false,
): Promise<void> => {
  const client = getClientInstance();
  let activeRecipientId: string | null = readFromLocalStorage(
    MINI_MESSENGER_VISIBLE_RECIPIENT_KEY,
  );

  let isAdmin = false;

  /**
   * Get active recipient from mini messenger at first
   * If we don't have active recipient we get this one from the first recipient
   */
  if (!activeRecipientId) {
    const {data} = await client.query<MessengerQuery>({query: MESSENGER_QUERY});
    const recipients = data.messenger?.initialData?.recipients;

    if (recipients?.length > 0) {
      const activeRecipient = sortBy(recipients, (recipient) => {
        return recipient.lastMessage
          ? -recipient.lastMessage.timestamp
          : Number.MAX_SAFE_INTEGER;
      })[0];

      activeRecipientId = activeRecipient.id;
      isAdmin = activeRecipient.user?.profile?.statuses?.isAdmin || false;
    }
  }

  return openMessengerWith({
    userId: activeRecipientId,
    replace: false,
    skipMiniMessenger: true,
    needGTMTrack: needGTMTrack && activeRecipientId && !isAdmin,
  });
};

export default openMessenger;
